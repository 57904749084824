import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core';
import { defaultFeedback } from 'hooks/use-is-access-blocked';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactComponent as AccessBlockedIcon } from 'view/images/update_app.svg';
import { ReactComponent as LoggiIcon } from 'view/images/loggi.svg';

export default function AccessBlockedDialog({ feedback, onButtonPress }) {
  const [isDialogOpen] = useState(true);

  return (
    <Dialog fullScreen open={isDialogOpen}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Grid container justify="space-between" alignItems="center">
          <LoggiIcon height="34px" />
        </Grid>
        <Box pt={6} px={2.5} flexGrow={1}>
          <Box pt={6} px={3} mb={2} width="100%">
            <AccessBlockedIcon />
          </Box>
          <Typography variant="h5" style={{ marginBottom: '1rem' }}>
            {feedback?.title ?? defaultFeedback.title}
          </Typography>
          <Typography>
            {feedback?.message ?? defaultFeedback?.message}
          </Typography>
        </Box>
        <Box pt={2} p={3} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={onButtonPress}
          >
            Entendi
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

AccessBlockedDialog.defaultProps = {
  feedback: null
};

AccessBlockedDialog.propTypes = {
  onButtonPress: PropTypes.func.isRequired,
  feedback: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string
  })
};
