import { getCurrentUserInfo } from '@loggi/driver-authentication';
import { useRemoteConfig } from '@loggi/firebase-feature-switches';
import featureSwitches from 'operations/feature-switches';

export const defaultFeedback = {
  title: 'Aplicativo bloqueado',
  message: 'Entre em contato com a base para mais informações sobre seu acesso.'
};

const isBaseBlocked = driver => {
  return baseItem => {
    const base = baseItem?.toLowerCase();
    const isBlocked = base === driver?.routingCode?.toLowerCase();
    const isLmcBlocked = base === driver?.lmcIdentification?.toLowerCase();
    return isBlocked || isLmcBlocked;
  };
};

export const useIsAccessBlocked = driverInfo => {
  const driver = driverInfo ?? getCurrentUserInfo();
  const accessBlocking = useRemoteConfig(featureSwitches.accessBlocking, {
    users: [],
    bases: [],
    feedback: defaultFeedback
  });

  if (!driver) {
    return {
      isDriverAccessBlocked: false,
      feedback: accessBlocking.feedback
    };
  }

  const isDriverBlocked = accessBlocking?.users?.some(
    blocked => blocked.id === driver?.id
  );
  const isDriverBaseBlocked = accessBlocking?.bases?.some(
    isBaseBlocked(driver)
  );

  return {
    isDriverAccessBlocked: isDriverBlocked || isDriverBaseBlocked,
    feedback: accessBlocking.feedback
  };
};
